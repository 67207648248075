import React from "react"
import "./media.scss"

const Media = ({ content }) => {
    const data = JSON.parse(content)
    return(
        <section className="media">

        </section>
    )
}

export default Media
